import { Button, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import './home_bg.css'
import { Helmet } from 'react-helmet'
export default function Home_bg() {
  return (

    
<div className="pickgradient">
<Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>{'ARK: Discover the Best'}</title>
    <meta
          name="title"
          content={'ARK: Discover the Best'}
        />
    <meta
          name="description"
          content={'The best entertainment for every mood. Subscribe now!'}
        />

<meta name="keywords" content={'It’s all here. Iconic games, latest journals, fresh affirmations and daily need reminders.'} />

        {/* <!-- Google / Search Engine Tags --> */}
       
       
        <meta itemprop="name" content={'ARK: Discover the Best'}
         />
        
    
   
    <meta
    itemprop="description"
    content={'The best entertainment for every mood. Subscribe now!'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content={`https://theark.app/`} />
  
        <meta property="og:type" content="website" />
      
        <meta property="og:title" content={'ARK: Discover the Best'} />
        
    
    <meta
          property="og:description"
          content={'The best entertainment for every mood. Subscribe now!'}
        />
        <meta property="og:image" content={require('../../images/icon.png')} />

      
        <meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={`https://theark.app/`} />
<meta property="twitter:title" content="ARK: Discover the best" />
<meta property="twitter:description" content="The best entertainment for every mood. Subscribe now!" />
<meta property="twitter:image" content={require('../../images/icon.png')} />

      
       
      </Helmet>
<img src={require('../../images/home_bg.jpg')} className='home_bg' />

<Grid container className='home_bg_maincont'>
<Grid container sm={8} xs={12}>
<Grid item sm={12} xs={12}>
<Typography className='home_bg_head'>
The best entertainment for every mood. <br/> Subscribe now!

</Typography>

</Grid>
<Grid item style={{maxWidth:'90%'}} sm={12} xs={12}>
<Typography className='home_bg_text'>
It’s all here. Iconic games, latest journals, fresh affirmations, astrology insights and daily need reminders.

</Typography>

</Grid>
<Grid container sm={12} xs={12}>
<Grid item >
<img src={require('../../images/app-store.png')} className='home_bg_app' onClick={()=>window.open("https://apps.apple.com/app/ark-discover-the-best/id6496356598")} />

</Grid>
<Grid item style={{paddingLeft:'2vw'}} >
<img src={require('../../images/play-store.png')} className='home_bg_app' onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.d6.ark")}/>

</Grid>

</Grid>

</Grid>

</Grid>



    </div>
  )
}
