import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './common/header/navbar';
import ScrollT from './common/STTP/scroll_Top';
import Footer from './common/footer/footer';
import Homepage from './homepage/homepage';
import Privacy_policy from './privacypolicy/Privacy&policy1';
import Terms_conditions from './privacypolicy/Terms&Conditions';
function App() {
  return (
    <BrowserRouter>
    <Navbar />
  <ScrollT />
  <Routes>
    <Route  path='/' element={<Homepage />}  />
    <Route  path='/privacy-policy' element={<Privacy_policy />}  />
    <Route  path='/terms-and-conditions' element={<Terms_conditions />}  />

  </Routes>
<Footer />
    </BrowserRouter>
  );
}

export default App;
