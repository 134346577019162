import React from 'react'
import './TBI_PrivacyPolicy_CSS.css';
import {Grid} from '@mui/material';


const Terms_conditions = () => {
    return (
        <div className="TBI_PP_Block">
        <Grid container xs={12} className="TBI_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid1">
                <h3  style={{color:'#cc0000'}} className="TBI_PrivacyPolicy_Block_MH TBI_PrivacyPolicy_Block_H">
                Terms and Conditions for Mobile Application Use 
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid2">
                <p className="TBI_PrivacyPolicy_Block_P1 TBI_PrivacyPolicy_Block_P">
               
                Welcome to ARK! Before you proceed to use our mobile application, please read the following Terms and Conditions carefully. For mobile application namely “ARK: Discover the Best” (“ARK”), a product of Dec6 Consulting Private Limited by accessing or using our mobile application, you agree to be bound by these Terms and Conditions. 
                               </p>

            
            </Grid>
   
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>1. Acceptance of Terms:  </b>

                By accessing or using our mobile application, you agree to be bound by these Terms and Conditions, our Privacy Policy, and any other policies or guidelines posted on our mobile application. 
                           </p><br/>
            </Grid>
            
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>2.Use of the Mobile Application:  </b> You agree to use our mobile application only for lawful purposes and in accordance with these Terms and Conditions. You shall not use our mobile application in any manner that could disable, overburden, damage, or impair the application or interfere with any other party's use of the application. 

                           </p><br/>
            </Grid>
            
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>3. User Accounts:</b> In order to access certain features of our mobile application, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your mobile device. You agree to accept responsibility for all activities that occur under your account or password. 

                           </p><br/>
            </Grid>
            
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>4. Intellectual Property Rights:</b> Our mobile application and its entire contents, features, and functionality are owned by Dec6 Consulting Pvt. Ltd. and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.  

                           </p><br/>
            </Grid>
            
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>5. Third-Party Links:</b> Our mobile application may contain links to third-party websites or services that are not owned or controlled by Dec6 Consulting Pvt. Ltd. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. 

                           </p><br/>
            </Grid>
            
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>6. Limitation of Liability:</b> In no event shall Dec6 Consulting Pvt. Ltd nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the mobile application; (ii) any conduct or content of any third party on the mobile application; (iii) any content obtained from the mobile application; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.   

                           </p><br/>
            </Grid>
          
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>7. Indemnification:</b> You agree to defend, indemnify, and hold harmless Dec6 Consulting Pvt. Ltd., its affiliates, and licensors, and their respective officers, directors, employees, contractors, agents, licensors, and suppliers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms and Conditions or your use of our mobile application.  

                           </p><br/>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>8. Changes to Terms and Conditions:</b> We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.    

                           </p><br/>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>9.  Governing Law:</b> These Terms and Conditions shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. 

                           </p><br/>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="TBI_PrivacyPolicy_Block_Grid3">
                <p className="TBI_PrivacyPolicy_Block_P2 TBI_PrivacyPolicy_Block_P">
                
                <b style={{color:'#cc0000'}}>10.  Contact Us:</b> If you have any questions about these Terms and Conditions, please contact us at  <a href='mailto:contact@theark.app' target='_blank' style={{color:'#CC0000'}}><b >contact@theark.app</b></a>. <br/>
                By downloading, installing, or using our mobile application, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, do not use our mobile application. 

                           </p><br/>
            </Grid>
       
       </Grid> </div>

    

    )
}

export default Terms_conditions
