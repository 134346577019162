import React, { useState } from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
// import { useLogoutUserMutation } from '../../../slices/usersApiSlice';

function Navbar() {
  const [navbarA,setNavbar] = useState(false);
// const [logoutUser,{isLoading}]=useLogoutUserMutation()
  const changeBackground = () => {
    if(window.scrollY >= 100)
    {
        setNavbar(true);
    }
    else {
        setNavbar(false);
    }
};
window.addEventListener('scroll',changeBackground);
      


return(
            <nav>
            <div style={{width:'100%',justifyItems:'center',display:'grid'}}>
            <div className={'Navbar_Items'}>

<Grid justifyContent={'flex-start'} display={'flex'} alignItems={'center'} container style={{paddingRight:'4rem'}}  className='gird_nav'>

<Grid container sm={12} xs={12}>
{/* <div style={{height:50,width:50,borderRadius:'50%',backgroundColor:'white',marginLeft:'2vw'}}> */}
<img  src={require('../../images/icon.png')} onClick={()=>window.location.href='/'}  style={{height:60,paddingLeft:'2vw'}} ></img>

{/* </div> */}
</Grid>
 
    </Grid>               
            </div>


            </div>





            </nav>
        );
    }
    
    export default Navbar;