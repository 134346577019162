import { CircularProgress, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import './below_bg.css'
import Crousel from '../../common/crousel/crousel'
import { useAllAffirmationsQuery, useAllCategoriesJournalQuery, useAllCategoriesQuery, useAllHoroscopesQuery } from '../../slices/apiSlice'
export default function Below_bg() {
  
  
  const {data:games,isLoading:isLoadingG}=useAllCategoriesQuery()
  const {data:affirmations,isLoading:isLoadingA}=useAllAffirmationsQuery()
  const {data:journals,isLoading:isLoadingJ}=useAllCategoriesJournalQuery()
  const {data:horoscopes,isLoading:isLoadingH}=useAllHoroscopesQuery()

  const reminders=['Water', 'Food', 'Medicine', "Health", "Fitness", "Alarms"]

  
  return (

    <div>
    <Container style={{paddingBottom:'5vh'}}>
<Grid container>
  <Grid item sm={12} xs={12}>

    <Typography className='head_main'>Games</Typography>
  </Grid>

  <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
   {isLoadingG?<CircularProgress  style={{color:'#CC0000',padding:'1vh 0px'}} /> :<Crousel data={games} type='Games'  />}
  </Grid>
</Grid>
     
<Grid container>
  <Grid item sm={12} xs={12}>

    <Typography className='head_main'>Journals</Typography>
  </Grid>

  <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
   {isLoadingJ?<CircularProgress  style={{color:'#CC0000',padding:'1vh 0px'}} /> :<Crousel data={journals} type='Journals' />}
  </Grid>
</Grid>

<Grid container>
  <Grid item sm={12} xs={12}>

    <Typography className='head_main'>Horoscopes</Typography>
  </Grid>

  <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
   {isLoadingH?<CircularProgress  style={{color:'#CC0000',padding:'1vh 0px'}} /> :<Crousel data={horoscopes} type='Horoscopes'  />}
  </Grid>
</Grid>
<Grid container>
  <Grid item sm={12} xs={12}>

    <Typography className='head_main'>Affirmations</Typography>
  </Grid>

  <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
   {isLoadingA?<CircularProgress  style={{color:'#CC0000',padding:'1vh 0px'}} /> :<Crousel data={affirmations} type='Affirmations'  />}
  </Grid>
</Grid>
<Grid container>
  <Grid item sm={12} xs={12}>

    <Typography className='head_main'>Reminders</Typography>
  </Grid>

  <Grid item textAlign={'-webkit-center'} sm={12} xs={12}>
{isLoadingA?<CircularProgress  style={{color:'#CC0000',padding:'1vh 0px'}} /> :    <Crousel type='Reminders' data={reminders} />
}  </Grid>
</Grid>


        </Container>




        </div>
  )
}
