import React from 'react'
import Below_bg from './bewlow_bg/below_bg'
import Home_bg from './home_bg/home_bg'

export default function Homepage() {
  return (
    <>
<div style={{height:'100vh'}}>

  
<Home_bg />

</div>
<Below_bg />
    </>

  )
}
