import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'


const baseQuery=fetchBaseQuery({baseUrl:'https://d6-ark-41dea18ec395.herokuapp.com/'})

export const apiSlice=createApi({
    baseQuery,
    tagTypes:[],
    endpoints:(builder=>({
        allAffirmations:builder.query({
            query:(data)=>({
               url:`api/affirmations`,
            })
           }),
           allCategories:builder.query({
            query:(data)=>({
                url:`api/category`,
              
            }),
            keepUnusedDataFor:5,

        }),
        allCategoriesJournal:builder.query({
            query:(data)=>({
                url:`api/category-journals`,
              
            }),
            keepUnusedDataFor:5,

        }),
        allHoroscopes:builder.query({
            query:()=>({
                url:'api/horoscopes',
            
            })
        }),

    }))
})


export const {useAllAffirmationsQuery,useAllCategoriesJournalQuery,useAllCategoriesQuery,useAllHoroscopesQuery} =apiSlice