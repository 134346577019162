import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import { Paper } from "@mui/material";
import './crousel.css'



function Crousel(props) {

    const {data}=props


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    dots:false,
    slidesToScroll: 1,
    initialSlide:1,

    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0

          }
        }
      ]
     };
  return (
    <div className="slider-container">
        
     {data?
     
     
    (props.type==='Games'? 
     <Slider dots={false} {...settings}>
        
        {
        
        
        data.map((val,idx)=>
        val.games.map((val1,idx1)=>
        <div key={idx1}>
         
        <img  src={val1.image} className="img_crousel" />
          
    </div> )
        )}
      
       
      </Slider>:props.type==='Horoscopes'? 
     <Slider dots={false} {...settings}>
        
        {
        
        
        data.map((val,idx)=>
        
        <div key={idx}>
         
        <img  src={val.image} className="img_crousel" />
          
    </div> 
        )}
      
       
      </Slider>:props.type==='Affirmations'? 
     <Slider dots={false} {...settings}>
        
        {
        
        
        data.map((val,idx)=>
        
        <div key={idx}>
         
        <div style={{backgroundColor:'#CC0000',color:'white',fontSize:18,alignContent:'center'}} className="img_crousel" >{val.affirmation}</div>
          
    </div> 
        )}
      
       
      </Slider>:props.type==='Reminders'? 
     <Slider dots={false} {...settings}>
        
        {
        
        
        data.map((val,idx)=>
        
        <div key={idx}>
         
        <div style={{backgroundColor:'#CC0000',color:'white',fontSize:20,alignContent:'center',textAlign:'center',fontWeight:'600'}} className="img_crousel" >{val}</div>
          
    </div> 
        )}
      
       
      </Slider>:<Slider dots={false} {...settings}>
        
        {
        
        
        data.map((val,idx)=>
        val.journals.map((val1,idx1)=>
        <div key={idx1}>
         
        <img  src={val1.image} className="img_crousel" />
          
    </div> )
        )}
      
       
      </Slider>): <Slider {...settings}>
        
        {<div>
         
            <img  src={require('../../images/BubbleWoods.jpg')} className="img_crousel" />
              
        </div>}
        <div>
         
            <img  src={require('../../images/BubbleWoods.jpg')} className="img_crousel" />
              
        </div>
        <div>
         
            <img  src={require('../../images/BubbleWoods.jpg')} className="img_crousel" />
              
        </div>
        <div>
         
            <img  src={require('../../images/BubbleWoods.jpg')} className="img_crousel" />
              
        </div>
        <div>
         
            <img  src={require('../../images/BubbleWoods.jpg')} className="img_crousel" />
              
        </div>
       
      </Slider>}
    </div>
  );
}

export default Crousel;
